export default { 

  tablesDatasetsVideosMathML (articleSummary) {
    let oneFound = null
    const returnVal = []
    if (articleSummary.hasTables) { 
      oneFound = true
      returnVal.push(true)
    } else  returnVal.push(null)

    if (articleSummary.hasMathML) {
      oneFound = true
      returnVal.push(true)
    } else  returnVal.push(null)

    if (!articleSummary.supplementary_names) {
      returnVal.push(null)
      returnVal.push(null) 
    } else {
      const fileNames = articleSummary.supplementary_names
      let cnt = 0
      for (let i = 0; i < fileNames.length; i++) {
        if (fileNames[i].includes('.xls')) cnt += 1
        else if (fileNames[i].includes('.csv')) cnt += 1
      }
      if (cnt > 0) {
        oneFound = true
        returnVal.push(cnt)
      } else returnVal.push(null)

      cnt = 0
      for (let i = 0; i < fileNames.length; i++) {
        if (fileNames[i].includes('.mov')) cnt += 1
        else if (fileNames[i].includes('.avi')) cnt += 1
        else if (fileNames[i].includes('.mp4')) cnt += 1
        else if (fileNames[i].includes('.wmv')) cnt += 1
      }
      if (cnt > 0) {
        oneFound = true
        returnVal.push(cnt)
      } else returnVal.push(null)
    }
    //console.log('rrrrrrrrrrrrr', returnVal)
    if (oneFound) return returnVal
    return null
  },
  totalValidationErrors (xsdValidationError, genericSchematronErrors, literatumSchematronErrors) {
    let total = 0
    if (xsdValidationError) total += 1
    if (genericSchematronErrors) total += genericSchematronErrors.length
    if (literatumSchematronErrors) total += literatumSchematronErrors.length
    return total          
  },
  totalValidationWarnings (articleSummary, literatumSchematronWarnings) {
    let total = 0
    total += Object.keys(articleSummary.warnings).length
    total += literatumSchematronWarnings.length
    return total          
  },
  articlePublishedOnline (article) {
    const p = article['published-online']
    if (!p) return p
    const dp = p['date-parts'][0]
    let returnVal =  dp[0].toString().concat('-', dp[1])
    if (dp.length === 3) returnVal += '-'.concat(dp[2])
    return returnVal
  },
  articlePublishedPrint (article) {
    const p = article['published-print']
    if (!p) return p 
    const dp = p['date-parts'][0]
    let returnVal =  dp[0].toString().concat('-', dp[1])
    if (dp.length === 3) returnVal += '-'.concat(dp[2])
    return returnVal
  },
  xsdValidationError (serverData) {
    if (serverData.xsdValidationResult.status === 'failed') {
      return serverData.xsdValidationResult.message
    }
    return null
  },
  genericSchematronErrors (serverData) {
    const returnVal = []
    if (serverData.genericSchematronValidationResult) {
      for (let i = 0; i < serverData.genericSchematronValidationResult.length; i++) {
        const tests =  serverData.genericSchematronValidationResult[i].tests
        for (let j = 0; j < tests.length; j++) {
          //console.log('ddda', tests[j])
          returnVal.push(tests[j])
        }
      }
    }
    return returnVal
  },
  literatumSchematronErrors (serverData) {
    const returnVal = []
    if (serverData.literatumSchematronValidationResult) {
      for (let i = 0; i < serverData.literatumSchematronValidationResult.length; i++) {
        const tests =  serverData.literatumSchematronValidationResult[i].tests
        for (let j = 0; j < tests.length; j++) {
          returnVal.push(tests[j])
        }
      }
    }
    return returnVal
  },
  literatumSchematronWarnings (serverData) {
    const returnVal = []
    if (serverData.literatumSchematronWarningsValidationResult) {
      for (let i = 0; i < serverData.literatumSchematronWarningsValidationResult.length; i++) {
        const tests =  serverData.literatumSchematronWarningsValidationResult[i].tests
        for (let j = 0; j < tests.length; j++) {
          returnVal.push(tests[j])
        }
      }
    }
    if (serverData.unsupportedTags) {
      for (let i = 0; i < serverData.unsupportedTags.length; i++) {
        const tag =  serverData.unsupportedTags[i]
        returnVal.push({ location: tag.location, message: 'Unsupported Tag: '.concat(tag.name) })
      }
    }
    return returnVal
  },
  contribGroupOn (article) {
    const contribGroup = {}
    const articleContributors = article.contributors.contributors
    if (!articleContributors) return {}
    let tmpList = []
    for (let i = 0; i < articleContributors.length; i++) {
      const ac = articleContributors[i]
      const rec = { selectionId: i.toString(), type: ac.attrs['contrib-type'] }
      if (ac.attrs.corresp) rec.corresp = ac.attrs.corresp
      for (let j = 0; j < ac.children.length; j++) {
        const child = ac.children[j]
        if (child.tagName === 'contrib-id') {
          rec.contribId = { type: child.attrs['contrib-id-type'], text: child.children[0].value }
        } else if (child.tagName === 'string-name' || child.tagName === 'name') {
          for (let k = 0; k < child.children.length; k++) {
            const grandChild = child.children[k]
            if (grandChild.children.length > 0) {
              rec[grandChild.tagName] = grandChild.children[0].value
            }
          }
        } else if (child.tagName === 'xref') {
          const xrefKey = 'xref-'.concat(child.attrs['ref-type'])
          if (!rec[xrefKey])  rec[xrefKey] = []
          //console.log('ccccczzz', child)
          let cval = 'unknown'
          if (child.children.length > 0) {
            let nextLevelChild = child.children[0]
            if (nextLevelChild.type === 'text') {
              cval = nextLevelChild.value
            } else if (nextLevelChild.children.length > 0) { 
              nextLevelChild = nextLevelChild.children[0]
              if (nextLevelChild.type === 'text') {
                cval = nextLevelChild.value
              } 
            }
          }
          rec[xrefKey].push({ rid: child.attrs.rid, value: cval })
        } else console.log('unsupported detailUItil  contribGroupOn tagName:', child.tagName)
      }
      //console.log('ddd', rec)
      tmpList.push(rec)
    }
    if (tmpList.length > 0) contribGroup.contributors = tmpList
  
    const articleAffiliates = article.contributors.affiliates
    if (articleAffiliates) {
      tmpList = []
      for (let i = 0; i < articleAffiliates.length; i++) {
        const af = articleAffiliates[i]
        const rec = { id: af.attrs.id, xml: '' }
        for (let j = 0; j < af.children.length; j++) {
          const child = af.children[j]
          if (child.type === 'tag') {
            rec.xml = rec.xml.concat('<', child.tagName, '>', child.children[0].value, '</', child.tagName, '>')
          } else rec.xml = rec.xml.concat(child.value)
        }
        //console.log('ddd', rec)
        tmpList.push(rec)
      }

    }

    if (tmpList.length > 0) contribGroup.affiliates = tmpList

    const articleAuthorNotes = article.contributors.authorNotes
    tmpList = []
    if (!articleAuthorNotes) return contribGroup
    for (let i = 0; i < articleAuthorNotes.length; i++) {
      const an = articleAuthorNotes[i]
      for (let j = 0; j < an.children.length; j++) {
        const child = an.children[j]
        const rec = { attrs: child.attrs, xml: '' }
        for (let k = 0; k < child.children.length; k++) {
          const gc = child.children[k]
          if (gc.type === 'tag') {
            if (gc.tagName === 'p') {
              for (let l = 0; l < gc.children.length; l++) {
                const ggc = gc.children[l]
                if (ggc.type === 'tag') {
                  rec.xml = rec.xml.concat('<', ggc.tagName, '>', ggc.children[0].value, '</', ggc.tagName, '>')
                } else rec.xml = rec.xml.concat(ggc.value)
              }
            } else {
              rec.xml = rec.xml.concat('<', gc.tagName, '>', gc.children[0].value, '</', gc.tagName, '>')
            }
          } else rec.xml = rec.xml.concat(gc.value)
        }
        //console.log('ddd', rec)
        tmpList.push(rec)
      }
    }
    if (tmpList.length > 0) contribGroup.authorNotes = tmpList

    return contribGroup 
  },
  referencesOn (article) {
    const articleReferences = article.references
    let references = null
    const tmpList = []
    for (let i = 0; i < articleReferences.length; i++) {
      const ref = articleReferences[i]
      //console.log('aaaa', ref.id)
      const rec = { id: ref.id, label: ref.label }
      for (let j = 0; j < ref.citation.children.length; j++) {
        const child = ref.citation.children[j]
        if (child.type !== 'text') {
          if (child.tagName === 'person-group') {
            rec.people = { type: 'tag', children: [] }
            for (let k = 0; k < child.children.length; k++) {
              const gc = child.children[k]
              if (gc.type === 'text') continue 
              if (gc.tagName.includes('name')) {
                const person = {}
                for (let l = 0; l < gc.children.length; l++) {
                  const ggc = gc.children[l]
                  if (ggc.children && ggc.children.length > 0) {
                    person[ggc.tagName] = ggc.children[0].value
                  }
                }
                rec.people.children.push(person)
                //console.log('ddddd', rec.people.children)
              } else {
                rec.people.children.push({ type: 'text', value: gc.tagName })
                //console.log('dddddaaa', rec.people.children)
              }
            }
          } else {
            rec[child.tagName] = child.children
          }
        }
      }
      tmpList.push(rec)
      //console.log('gggggg', rec)
    }
    if (tmpList.length > 0) references = tmpList
    return references
  },
  annotations (serverData) {
    //todo remove this when reading annoations rom serverData
    if (!serverData) return null
    const returnVal = []
    returnVal.push({ id: 1, offset: 4, test: 'impact of interferon (IFN) signaling' })
    returnVal.push({ id: 1, offset: 4, test: '' })

    return returnVal
  },
}

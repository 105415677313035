<template>
  <section v-if="mounted"> 
    <br>
    <b-card>
      <v-card-title >
        <h3 v-if="articleSummary.title && articleSummary.title.length > 0" v-html="$sanitize(articleSummary.title)"></h3>
        <h3 v-else class="text-danger"> Title Missing</h3>
      </v-card-title>
      <b-row>
        <b-col :cols="9">
   
        </b-col>
      </b-row>
      <br>
      <b-row v-if="instrumentedAbstracts.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <span v-for="(abstractHtml, index0) in instrumentedAbstracts"  :key="index0">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span @click="handleDomTextClick" v-html="abstractHtml"></span>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <b-row>
        <b-col :cols="9"> 
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span @click="handleDomTextClick" v-html="instrumentedBody"></span>
        </b-col>
        <b-col :cols="3">
          <div :class="navClass">
            <b-nav
              vertical
              small 
              class="wrap-border"
            >
              <b-nav-item 
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                @click.stop="annotatePressed()"
              >
                <feather-icon icon="MessageSquareIcon" />
                Annotate
              </b-nav-item>
            </b-nav>
            <b-modal
              id="annotation-modal-id" 
              centered
              title="Annotation" 
              ok-title="Accept"
              cancel-title="Cancel"
              size="lg"
              @ok="updateAnnotationNote"
            >
              <annotation-note-entry ref="annotationNoteEntryRef"  :selected-annotation="selectedAnnotation"/>
            </b-modal>

            <br><br>

            <b-nav 
              vertical
              small
              class="wrap-border"
            > 
              <b-nav-item 
                v-for="(header, index) in articleHeaders"  :key="index"
                @click="headerSelected(header.id)"
              >
                <span v-if="pillStatusOn(header.id)"><b><small>{{ headerTitle (header.title) }}</small></b></span>
                <span v-else><small>{{ headerTitle (header.title) }}</small></span>
              </b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row> 
      <br>
      <b-row v-if="acknowlegments.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <h4 id="back-acknowlegements">Acknowledgments</h4>
          <span v-for="(ack, index0) in acknowlegments"  :key="index0">
            <div>
              <span v-for="(child, index) in ack.children"  :key="index">
                <tag-dispatcher
                  v-if="child.type === 'tag'"
                  :tag="child"
                  parent="Acknowledgments"
                  selected="article"
                  :images="webImgeDict"
                />
                <span v-else-if="child.type === 'text'">
                  {{ child.value }}
                </span>
              </span> 
            </div>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
      <b-row v-if="backFootnotes.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <h4 id="back-footnotes">Footnotes</h4>
          <span v-for="(fn, index0) in backFootnotes"  :key="index0">
            <div>
              <span v-for="(child, index) in fn.children"  :key="index">
                <tag-dispatcher
                  v-if="child.type === 'tag'"
                  :tag="child"
                  parent="Footnotes"
                  selected="article"
                  :images="webImgeDict"
                />
              </span> 
            </div>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
      <b-row v-if="backSections.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <span v-for="(sec, index0) in backSections"  :key="index0">
            <h4 :id="sec.id">{{ sec.name }}</h4>
            <div>
              <span v-for="(child, index) in sec.children"  :key="index">
                <tag-dispatcher
                  v-if="child.type === 'tag'"
                  :tag="child"
                  parent="Back"
                  selected="article"
                  :images="webImgeDict"
                />
              </span> 
            </div>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
      <b-row v-if="references && references.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <h4 id="back-references">References</h4>
          <br>
          <span v-for="(ref, index_ref) in references"  :key="index_ref">
            <b-row>
              <b-col cols="1">
                <span style="padding-left:1rem;">
                  <b-button 
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    square
                    size="sm"
                    variant="outline-primary"
                    href="#"
                    style="margin:-1rem;"
                    @click="activateReferences('pbm-body-'.concat(ref.id))"
                  >
                    <feather-icon icon="CornerLeftUpIcon" />
                    <span :id="'bibr-'.concat(ref.id)"> {{ ref.label }}</span>
                  </b-button>
                </span>
              </b-col>
              <b-col v-if="ref.collab && ref.collab[0]" cols="11" >
                {{ ref.collab[0].value }}
              </b-col>
              <b-col v-if="ref.people" cols="11" >
                <span v-for="(person, index_ref_p) in ref.people.children" :key="index_ref_p">
                  <span v-if="person['given-names']"> {{ person['given-names'] }} {{ person.surname }}, </span>
                </span>
                <span v-if="ref['publisher-name']">
                  {{ ref['publisher-name'][0].value }}
                </span>
                <span v-if="ref['publisher-loc']">
                  {{ ref['publisher-loc'][0].value }}
                </span>
                <span v-if="ref['article-title']">{{ ref['article-title'][0].value }}, </span>
                <span v-if="ref.source"> <i>{{ ref.source[0].value }}</i>, </span>
                <span v-if="ref.volume"><b>{{ ref.volume[0].value }}</b>, </span>
                <span v-if="ref.fpage"> {{ ref.fpage[0].value }}</span>
                <span v-if="ref.lpage">-{{ ref.lpage[0].value }}</span>
                <span v-if="ref.year">({{ ref.year[0].value }}).</span>
              </b-col>
            </b-row>
            <hr>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
    </b-card>
  </section>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'
import detailUtil from '@/views/pubmill/detailUtil'

import {
  BButton, BCard, BRow, BCol, BNav, BNavItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import AnnotationNoteEntry from './AnnotationNoteEntry.vue'

export default {
  components: {
    BButton,
    BCard,
    BRow, 
    BCol,
    BNav,
    BNavItem,
    AnnotationNoteEntry,
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    TagDispatcher: () => import('@/views/pubmill/reader/web/article/tags/TagDispatcher.vue'),
  },
  directives: {
    Ripple,
  },

  data () {
    return {
      article: null,
      articleSummary: null,
      webImgeDict: null,
      imageNames: null,
      selected: 'article',
      instrumentedAbstracts: null,
      instrumentedBody: null,
      backSections: null,
      backFootnotes: null,
      acknowlegments: null,
      articleHeaders: null,
      references: null,
      mounted: null,
      selectedAnnotation: null,
      annotationNotes: []
    }
  },
  computed: {  
    navClass() {
      return 'stickyNavbar'
    },
  },
  watch: {
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  updated () { 
    //console.log('UPDATED lifecycle hook after mounted to ensure MathJax rendering')
    window.MathJax.typeset()
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  mounted () {
    this.refreshData()
  },
  methods: {
    handleDomTextClick(e) {
      const classList = e.target.classList
      for (let i = 0;  i < classList.length; i++) {
        const className  = classList.item(i)
        if (className.includes('pbm-annotation-')) {
          const noteIdClickedIntext = className.split('pbm-annotation-')[1].split('"')[0]
          this.annotationMarkerClicked(noteIdClickedIntext)
        }
      } 
    },
    getArticleReview () {
      const queryParams = {
        issn: this.$route.params.issn,
        volume: this.$route.params.vol,
        issue: this.$route.params.issue,
        file_name: this.$route.params.file_name,
        session_id: this.$route.params.session_id,
        reviewer_id: this.$route.params.reviewer_id,
      }
      publicationApi.getArticleReview(queryParams, this, null)
    },
    backFromGetArticleReview (serverData) {
      console.log('bbbbbb', serverData)
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        this.article = serverData.article_json
        this.articleSummary = serverData.articleSummary
        this.annotationNotes = serverData.existingNotes
    
        const currentPublicationName = this.$store.state.pubmillGlobalStore.currentPublicationObject.name
        if (!currentPublicationName) {
          console.log('currentPublicationName not found resetting from issn', this.$route.params.issn)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationId', this.$route.params.issn)
        }
        this.initData()
      } 
    },
    updateAnnotationNote () {

      const notesRecord = {
        id: this.selectedAnnotation.id
      }
      let notes = null
      if (this.$refs.annotationNoteEntryRef) {
        notes = this.$refs.annotationNoteEntryRef.notes
        if (notes !== null) { 
          console.log('annotation notes', notes)
          const tmp = notes.trim().replace(/\s+/g, '')
          if (tmp === '' || tmp === '<p></p>') {
            notes = null
          } 
          notesRecord.notes = notes
        }
      }
      if (notes === null) return
      const queryParams = {
        issn: this.$route.params.issn,
        volume: this.$route.params.vol,
        issue: this.$route.params.issue,
        file_name: this.$route.params.file_name,
        session_id: this.$route.params.session_id, 
        reviewer_id: this.$route.params.reviewer_id,
        notes_record: notesRecord,
      }

      const abstractScope = document.getElementById('pbm-jats-abstract')
      const abstractExecutiveSummaryScope = document.getElementById('pbm-jats-abstract-executive-summary')
      const bodyScope = document.getElementById('pbm-jats-body')
      this.article.abstract = []
      if (abstractExecutiveSummaryScope)  this.article.abstract.push(abstractExecutiveSummaryScope.outerHTML)
      if (abstractScope)  this.article.abstract.push(abstractScope.outerHTML)
      if (bodyScope)  this.article.body = bodyScope.outerHTML

      queryParams.article_json = this.article
   
      console.log('qqqq', queryParams)

      publicationApi.updateAnnotationNote(queryParams, this, null)
      
    },
    
    backFromUpdateAnnotationNote (serverData) {
      if (serverData.error) {
        console.log('backFromUpdateAnnotationNote with error', serverData.error)
      }
      console.log('backFromUpdateAnnotationNote success')  
      this.refreshData()

    }, 
    refreshData () {
      this.getArticleReview()
    },
    initData () {
      this.getWebImageUrls()
      this.articleHeaders = []
      this.initAbstracts()
      this.initBody()
      this.initTables()
      this.initMiscBack()
      this.references =  detailUtil.referencesOn(this.article)
      if (this.references && this.references.length > 0) {
        this.articleHeaders.push({ id: 'back-references', title: 'References' })
      }
      /*
      if (this.annotationNotes) {
        //give dom  time to load
        setTimeout(() => this.initNotes(), 500) 
      }
      */
    },
    //recursive
    getSelectionNodes (childList, allNodes, sel) {
      childList.forEach(node => {
        //console.log('node:', node, 'nodoType', node.nodeType, node.constructor)

        const nodeSel = sel.containsNode(node, true)
        //console.log('nodeSel', nodeSel)
        // if is not selected
        if (!nodeSel) return

        const tempStr = node.nodeValue
        //console.log('nodeValue:', tempStr)

        if (node.nodeType === 3 && tempStr.replace(/^\s+|\s+$/gm, '') !== '') {
          //console.log('nodo agregado')
          allNodes.push(node)
        }

        if (node.nodeType === 1) {
          //recursive
          if (node.childNodes) this.getSelectionNodes(node.childNodes, allNodes, sel)
        }
      })
    },
    instrumentSelectedText (selectedText) {
    
      const annotationClass = 'pbm-annotation-'.concat(this.selectedAnnotation.id)

      const range = selectedText.getRangeAt(0)
      //CHECK TO SEE IF SELECTED TEXT IS ALL IN THE SAME TAG
      //console.log('startContainer == endContainer', range.startContainer === range.endContainer)
      if (range.startContainer === range.endContainer) {
        const span = document.createElement('span')
        span.className = 'pbm-text-annot '.concat(annotationClass)
        range.surroundContents(span)
        selectedText.removeRange(range)
        return
      }
      
      //IF SELECTED TEXT IS NOT ALL IN THE SAME TAG
      //use this solution from https://stackoverflow.com/questions/29894781/javascript-selected-text-highlighting-prob
      const allNodes = []
      this.getSelectionNodes(range.commonAncestorContainer.childNodes, allNodes, selectedText)
      
      allNodes.forEach((node, index, listObj) => {
        const { nodeValue } = node
        let text
        let prevText
        let nextText
   
        if (index === 0) {
          prevText = nodeValue.substring(0, range.startOffset)
          text = nodeValue.substring(range.startOffset)
        } else if (index === listObj.length - 1) {
          text = nodeValue.substring(0, range.endOffset)
          nextText = nodeValue.substring(range.endOffset)
        } else {
          text = nodeValue
        }
        const span = document.createElement('span')
        if (index === 0) {
          span.className = 'pbm-text-annot '.concat(annotationClass)
        } else {
          span.className = 'pbm-text-annot-secondary '.concat(annotationClass)
        }
        span.append(document.createTextNode(text))
        const { parentNode } = node
        parentNode.replaceChild(span, node)
        if (prevText) {
          const prevDOM = document.createTextNode(prevText)
          parentNode.insertBefore(prevDOM, span)
        }
        if (nextText) {
          const nextDOM = document.createTextNode(nextText)
          parentNode.insertBefore(nextDOM, span.nextSibling)
        }
      })
      selectedText.removeRange(range)
      
      //console.log('current DOM:', annotationScope.outerHTML)
  
    },
    annotatePressed () {

      const selectedText = window.getSelection()

      //CHECK TO SEE IF SELECTED TEXT IN BROWSER IS WITHIN PROPER SCOPE
      let selectedTextNode = selectedText.anchorNode
      if (selectedTextNode.nodeType === 3) { 
        // use parent if text node 
        selectedTextNode =  selectedTextNode.parentNode 
      } 
      const abstractScope = document.getElementById('pbm-jats-abstract')
      const abstractExecutiveSummaryScope = document.getElementById('pbm-jats-abstract-executive-summary')
      const bodyScope = document.getElementById('pbm-jats-body')
      if ((!abstractScope || !abstractScope.contains(selectedTextNode))
          &&  (!abstractExecutiveSummaryScope || !abstractExecutiveSummaryScope.contains(selectedTextNode))
          &&  (!bodyScope || !bodyScope.contains(selectedTextNode))) {
        return
      } 

      this.selectedAnnotation = { id: (new Date().getTime()).toString() }

      this.instrumentSelectedText(selectedText)

      this.$bvModal.show('annotation-modal-id')
    },
    annotationMarkerClicked (noteIdClickedIntext) {
      console.log('ggggg1', noteIdClickedIntext)
      for (let i = 0; i < this.annotationNotes.length; i++) {
        const ann = this.annotationNotes[i]
        console.log('ggggg2', ann)
        if (ann.id === noteIdClickedIntext) {
          this.selectedAnnotation = ann
          this.$bvModal.show('annotation-modal-id')
          break
        }
      }
    },
    activateMathJax () {
      setTimeout(() => this.mathJaxReady(), 500) 
    },
    activateReferences(id) {
      setTimeout(() => this.refReady(id), 500) 
    },
    refReady (id) {
      //console.log('tttttt ref ready', id, this.$refs[id])
      this.scrollToRef(id)
    },
    mathJaxReady () {
      //console.log('tttttt ref ready', id, this.$refs[id])
      window.MathJax.typeset()
    },
    headerTitle (title) {
      if (title.length > 25) {
        return title.substring(0, 25).concat('...')
      } 
      return title
    },
    pillStatusOn (refName) {
      if (this.selected === refName) {
        return true
      }
      return false
    },
    imageLinkOn (imageName) {
      const rec = this.webImgeDict[imageName]
      if (rec) {
        return rec.url 
      } 
      return ''
    },
    initAbstracts () {
      this.instrumentedAbstracts = []
      if (!this.article.abstract) return
      for (let i = 0; i < this.article.abstract.length; i++) {
        const abstract = this.article.abstract[i]
        this.instrumentedAbstracts.push(abstract)
        //console.log('aaaaaaaaaa', abstract)
        let id = 'pbm-jats-abstract'
        let name = 'Abstract'
        if (abstract.split('</h4>')[0].includes('-executive-summary')) {
          id = id.concat('-executive-summary')
          name = 'Significance'
        }
        this.articleHeaders.push({ id, title: name })
      }
    },
    initBody () { 
      this.instrumentedBody = this.article.body
      console.log('ddd2122', typeof this.instrumentedBody)
      if (!this.instrumentedBody) return
      const nodes =   this.instrumentedBody.split('<pbm-section-')
      if (nodes.length < 2) return
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const sect = nodes[i].split('</pbm-section')[0]
        const id = sect.split('>')[0]
        const title = sect.split('<h4>')[1].split('</h4>')[0]
        //console.log('bbbbbb', id, title)
        this.articleHeaders.push({ id, title })
      }
    },
    initNotes () {
      for (let i = 0; i < this.annotationNotes.length; i++) {
        const rec = this.annotationNotes[i]
        const htmlCollection  = document.getElementsByClassName('pbm-annotation-'.concat(rec.id))
        for (let j = 0; j < htmlCollection.length; j++) {
          const element  = htmlCollection.item(j)
          element.firstChild.addEventListener('click', event => {
            //event.preventDefault()
            console.log('bbcc clicked: ', event.target)
          })
        }
      }
 
      /*
      const collection = document.getElementsByClassName("example");
      this.$refs['mydiv'].firstChild.addEventListener('click', function(event) {
        event.preventDefault();
        console.log('clicked: ', event.target);
      })
      */
      //this.initFiguresFromAbstract(this.articleBodyChildren)

    },
    initTables () {
      const nodes =   this.instrumentedBody.split('<pbm-table-wrapper>')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-table-wrapper>'.concat(nodes[i].split('</pbm-table-wrapper>')[0], '</pbm-table-wrapper>')
        const newWrapper = wrapper.replace('<table>', '<table width="98%" style="margin: 10px;">')
        this.instrumentedBody = this.instrumentedBody.replace(wrapper, newWrapper)
      }
    }, 
    initFiguresAndInlineImagesInBody () {
      //'<pbm-img-wrapper pbm-img-name="pnas.2116730119fig01"></pbm-img-wrapper>'
      //find and replace all pbm-img-wrapper  ( thiese will be within figures or inline) 
      const nodes =   this.instrumentedBody.split('<pbm-img-wrapper ')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-img-wrapper '.concat(nodes[i].split('</pbm-img-wrapper>')[0], '</pbm-img-wrapper>')
        const imgName = wrapper.split('pbm-img-name="')[1].split('">')[0]
        const src =  this.imageLinkOn(imgName)
        const newWrapper = '<pbm-img-wrapper '.concat('pbm-img-name="', imgName, '"> <img src="', src, '" style="padding: 10px;" class="img-fluid">', '</pbm-img-wrapper>')
        this.instrumentedBody = this.instrumentedBody.replace(wrapper, newWrapper)
        this.mounted = true
      } 

    }, 
    initMiscBack () {
      this.acknowlegments = []
      this.backFootnotes = []
      this.backSections = []
      if (!this.article['miscl-back']) return
      if (this.article['miscl-back'].ack && this.article['miscl-back'].ack.length > 0) {
        this.acknowlegments = this.article['miscl-back'].ack
        this.articleHeaders.push({ id: 'back-acknowlegements', title: 'Acknowlegments' })
      }
      if (this.article['miscl-back'].footnotes && this.article['miscl-back'].footnotes.length > 0) {
        this.backFootnotes = this.article['miscl-back'].footnotes
        this.articleHeaders.push({ id: 'back-footnotes', title: 'Footnotes' })
      }
      for (let i = 0; i < this.article['miscl-back'].sections.length; i++) {
        const section = this.article['miscl-back'].sections[i]
        const id = 'back-section-'.concat(i)
        //console.log('tttt section', id, section)
        let name = section.type
        let sectionChildren = section.children 
        if (section.children[0].tagName === 'title') {
          name = section.children[0].children[0].value
          sectionChildren = []
          for (let j = 0; j < section.children.length; j++) {
            if (j === 0) continue
            sectionChildren.push(section.children[j])
          }
        } else if (section.children[0].tagName === 'supplementary-material') {
          name = 'Supporting Information'
          sectionChildren = []
          for (let j = 0; j < section.children.length; j++) {
            if (j === 0) continue
            sectionChildren.push(section.children[j])
          }
        }
        this.backSections.push({ 
          id, 
          name, 
          type: section.type, 
          children: 
          sectionChildren 
        })
        this.articleHeaders.push({ id, title: name })
      }
    },
    headerSelected (refName) {
      this.selected = refName
      this.scrollToRef(refName)
    },
    scrollToRef (refName) {
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('Article Detail Web scrolling to elel', refName, element)
      //element.scrollIntoView({ behavior: 'smooth' })
      //element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
      
    },
    getWebImageUrls () {
      this.imageNames = []
      for (let i = 0; i < this.articleSummary.graphics_in_xml.length; i++) {
        let xlink =  this.articleSummary.graphics_in_xml[i].xlink
        
        let delim = '.tif'
        if (!xlink.endsWith(delim)) {
          delim = '.jpeg'
          if (!xlink.endsWith(delim)) {
            delim = '.gif'
            if (!xlink.endsWith(delim)) {
              delim = null
            }
          }
        }
        if (delim != null) {
          xlink = xlink.split(delim)[0]
        }
        this.imageNames.push(xlink)
      }
  
      let fileStatus =  'published'
      if (this.inProduction) {
        fileStatus = 'production'
      }
      const queryParams = {
        issn: this.$route.params.issn,
        volume: this.$route.params.vol,
        issue: this.$route.params.issue,
        doi: this.articleSummary.doi,
        file_status: fileStatus,
        image_names: this.imageNames,
        size: 'large'
      } 
      fileMgrApi.getWebImageUrls(queryParams, this, null)
    },
    backFromGetWebImageUrls (serverData) {
      if (serverData.error) {
        console.log('eeeeee', serverData.error)
      } else {
        //console.log('iiiiii', serverData)
        this.webImgeDict =  serverData
        if (!this.instrumentedBody) return
        this.initFiguresAndInlineImagesInBody()
        //message-square.svg
      } 
    },
  }, 
}
</script>

<style>
  .stickyNavbar {
    overflow: hidden;
    position: fixed;
    top: 170px;
  }
  .pbm-text-annot {
      cursor: pointer;
      background:yellow;
      padding-left:20px;
  }
  .pbm-text-annot:before {
      content: '';
      cursor: pointer;
      background:url('/message-square.svg');
      background-size:cover;
      position:absolute;
      width:20px;
      height:20px;
      margin-left:-20px;
  }
  .pbm-text-annot-secondary {
    cursor: pointer;
    background:yellow;
    padding-left:20px;
  }

</style>

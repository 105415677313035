<template>
  <section> 

    <quill-editor v-model="notes" :options="editorOptions"></quill-editor>

  </section>
</template>
 
<script>

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'

export default {

  components: {
    quillEditor,
  }, 
 
  props: {
    selectedAnnotation: {
      type: Object,
      default: null
    }, 
  },
  data () {
    return {
      notes: null,
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            //['bold', 'italic', 'underline', 'strike'],
            ['bold', 'italic', 'underline'],
            //['blockquote', 'code-block'],
            //[{ 'header': 1 }, { 'header': 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            //[{ 'script': 'sub' }, { 'script': 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            //[{ 'direction': 'rtl' }],
            //[{ 'size': ['small', false, 'large', 'huge'] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }]
            //['clean']
            //['link', 'image', 'video']
          ]
        }
      },
    }
  },
  mounted () {
    if (this.selectedAnnotation.notes) {
      this.notes = this.selectedAnnotation.notes
    }
  },
  methods: {

  }
}
</script>
